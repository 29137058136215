<template>
  <div>
    <b-card>
      <b-button
        variant="primary"
        @click="goToUpsert('new')"
      >
        <feather-icon icon="PlusCircleIcon" />
        {{ $t('projects.new') }}
      </b-button>
    </b-card>
    <b-card no-body>
      <b-table
        striped
        hover
        responsive
        class="position-relative"
        :items="readItems"
        :fields="fields"
      >
        <template #cell(name)="data">
          {{ data.item.name }}
        </template>
        <template #cell(installationName)="data">
          {{ data.item.Installation.name }}
        </template>
        <template #cell(action)="data">
          <b-link
            @click="goToUpsert(data.item.id)"
          >
            <feather-icon
              icon="EditIcon"
              class="mr-50"
              size="20"
            />
          </b-link>
        </template>
        <template #cell(calculate)="data">
          <b-link
            @click="goToCalc(data.item.id)"
          >
            <feather-icon
              icon="ActivityIcon"
              class="mr-50"
              size="20"
            />
          </b-link>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BTable,
  BLink,
  BButton,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BTable,
    BLink,
    BButton,
  },
  data() { return { items: [] } },
  computed: {
    readItems() { return this.items },
    fields() {
      return [
        { key: 'name', label: this.$t('projects.table.name'), sortable: true },
        { key: 'installationName', label: this.$t('projects.table.installationName'), sortable: true },
        { key: 'action', label: this.$t('projects.table.edit'), sortable: true },
        { key: 'calculate', label: this.$t('projects.table.calculate'), sortable: true },
      ]
    },
  },
  async created() { await this.load() },
  methods: {
    goToCalc(id) {
      this.$router.push({ name: 'pages-project-calc-blindage', query: { id } })
    },
    goToUpsert(id) {
      this.$router.push({ name: 'pages-project-upsert', params: { id } })
    },
    async load() {
      this.items = await this.$store.dispatch('project/index')
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@media (max-width: 767px){
  .small-devices-filterby{
    display: block;
  }
}
.dropdown button {
    padding: 0px;
}
.em-dropdown .dropdown-menu {
  max-height: 100px;
  overflow-y: hidden;
}
.dropdown-item{
  padding: 0 4px;
}
</style>
